@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100vh;
}

@font-face {
  font-family: 'Noto Serif Display';
  src: url('./fonts/NotoSerifDisplay-Bold.ttf') format('truetype');
}

@layer components {
  .skeleton-bar {
    @apply rounded-sm bg-gray-200 dark:bg-gray-700;
  }
}
